/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated';
import { flows } from '../../../util/appConstants';
import { clientConfigInParameters } from '../../../data/OAuthClient';

class ClientId extends React.Component {

    constructor(props) {
        super(props);
        this._parameterName = 'client_id';
    }

    handleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {

            let updatedParameters = this.props.collection.parameters;
            Object.keys(clientConfigInParameters).forEach((key) => {
                try {
                    updatedParameters = updatedParameters.withUpdatedValue(key, undefined);
                } catch (e) {
                    // ignoring parameter that doesn't exist
                }
            });
            Object.keys(newValue.meta).forEach((metaKey) => {
                try {
                    updatedParameters = updatedParameters.withUpdatedValue(metaKey, newValue.meta[metaKey]);
                } catch (e) {
                    // ignoring parameter that doesn't exist
                }
            });
            updatedParameters = updatedParameters.withUpdatedValue('client_from_environment', newValue.id);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('client_id', newValue.value)
                    .withUpdatedValue('client_from_environment', undefined)
                    .withUpdatedValue('can_do_introspect',
                        !this.props.collection.parameters.use_separate_client_for_introspection);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'clear') {
            let updatedParameters = this.props.collection.parameters.withUpdatedValue('client_from_environment', undefined);
            Object.keys(clientConfigInParameters).forEach((key) => {
                try {
                    updatedParameters = updatedParameters.withUpdatedValue(key, undefined);
                } catch (e) {
                    // ignoring parameter that doesn't exist
                }
            });
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    static can_do_flow(client, flow) {
        switch (flow) {
            case flows.implicit:
                return client.can_do_implicit_flow;
            case flows.code:
                return client.can_do_code_flow;
            case flows.hybrid:
                return client.can_do_hybrid_flow;
            case flows.client_credentials:
                return client.can_do_client_credentials_flow;
            case flows.device:
                return client.can_do_device_flow;
            case flows.password:
                return client.can_do_ropc_flow;
            case flows.introspect:
                return client.can_do_introspect;
            case flows.revoke:
                return client.can_do_revoke_flow;
            case flows.refresh:
                return client.can_do_refresh_flow;
            case flows.token_exchange:
                return client.can_do_token_exchange;
            case flows.ciba:
                return client.can_do_ciba_flow;
            case flows.vci:
                return true;
            case flows.precode:
                return true;
            default:
                return false;
        }
    }

    render() {
        const clientId = this.props.collection.parameters.client_id;
        const clientName = this.props.collection.parameters.client_name;

        const label = clientName ? clientName + ' (' + clientId + ')' : clientId;
        const selectedOption = { value: clientId, label };
        let clientOptions = [];
        if (this.props.environment && this.props.environment.clients) {
            Object.entries(this.props.environment.clients).forEach(([key, client]) => {
                if (ClientId.can_do_flow(client, this.props.flow)) {
                    const entryLabel = client.client_name ? client.client_name + ' (' + client.client_id + ')' : client.client_id;
                    clientOptions.push({ value: client.client_id, label: entryLabel, meta: client, id: key });
                }
            });
        }

        return (
            <div className="flex-auto">
                <Creatable
                    isClearable
                    inputId="clientid"
                    placeholder="Select or enter a Client ID"
                    components={makeAnimated()}
                    defaultValue={(clientId ? selectedOption : undefined)}
                    options={clientOptions}
                    onChange={this.handleChange}
                    className="select-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#f2f3f6',
                            primary: '#626c87'
                        }
                    })}
                />
            </div>
        )
    }
}

export default ClientId;
