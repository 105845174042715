/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContainer from '../AppContainer';
import PageContainer from '../PageContainer';
import NotFound from '../NotFound';
import CallbackContainer from '../CallbackContainer';
import CreateDemoFlowContainer from '../CreateDemoFlowContainer';
import withTracker from '../WithTracker';
import ErrorBoundary from '../ErrorBoundary';
import Login from '../Login';
import LoginCallback from '../LoginCallback';
import { Navigate } from 'react-router';
import { LOCAL_STORAGE_ID_TOKEN, OAUTH_TOOLS_LOGIN } from '../util/appConstants';
import { decodeAndValidateJwtWithKeys } from '../util/jwtUtil';
import WhatsNew from './WhatsNew';

class Router extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: null,
            fragment: null,
            error: null,
            userIsLoggedIn: false,
            showNotification: false,
            showRestartButton: false,
            showWhatsNew: false,
            notificationMessage: ''
        };
    }

    checkIdToken = async (idToken) => {
        if (idToken) {
            const decodedValidatedJwt = await decodeAndValidateJwtWithKeys(idToken, [], 'id_token',
                OAUTH_TOOLS_LOGIN.jwks_endpoint, '');

            const jwtClaims = decodedValidatedJwt.decodedToken.body;

            const epochNow = Math.floor(Date.now() / 1000);
            return [jwtClaims.oauth_tools &&
            jwtClaims.aud === 'oauth-tools' &&
            jwtClaims.iss === OAUTH_TOOLS_LOGIN.issuer &&
            epochNow <= jwtClaims.exp &&
            (!jwtClaims.nbf || epochNow + 180 >= jwtClaims.nbf), jwtClaims.sub];
        }
        return [false, null]
    }

    loginHandler = (idToken) => {
        if (idToken) {
            this.checkIdToken(idToken)
                .then(([tokenValid, email]) => {
                    if (tokenValid) {
                        this.setState({
                            userIsLoggedIn: 'true',
                            user: email,
                            error: null
                        })
                    } else {
                        if (localStorage.getItem(LOCAL_STORAGE_ID_TOKEN)) {
                            // user was already logged in, id_token has expired
                            localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN)
                            this.setState({
                                userIsLoggedIn: false,
                                user: email,
                                error: null
                            })
                        } else {
                            this.setState({
                                userIsLoggedIn: false,
                                user: null,
                                error: 'Access not allowed'
                            })
                        }
                    }
                })
        } else {
            this.setState({
                userIsLoggedIn: false,
                error: 'Access not allowed'
            })
        }

    }

    setCallbackParams = (flow, query, fragment) => {
        this.setState({
            flow,
            query,
            fragment
        });
    };


    restartApp = () => {
        if (window && typeof window.nodeRequire !== 'undefined') {
            window.nodeRequire('electron').ipcRenderer.send('restart_app');
        }
    }

    closeNotification = () => {
        this.setState({ showNotification: false })
    }

    showWhatsNew = () => {
        this.setState({ showWhatsNew: true })
    }

    hideWhatsNew = () => {
        this.setState({ showWhatsNew: false })
    }

    setNotification = (appVersion, showNotification, notificationMessage, showRestartButton) => {
        this.setState({
            appVersion,
            showNotification,
            showRestartButton,
            notificationMessage
        })
    }

    render() {
        if (window && typeof window.nodeRequire !== 'undefined') {
            const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
            ipcRenderer.removeAllListeners('update_available');
            ipcRenderer.removeAllListeners('update_downloaded');
            ipcRenderer.on('update_available', (event, message) => {
                this.setNotification(message, true, 'A new update is available. Downloading now...', false);
            });
            ipcRenderer.on('update_downloaded', (event, message) => {
                this.setNotification(message, true, 'Update Downloaded. It will be installed on restart. Restart now?', true);
            });
        }
        console.log('%cOAuth Tools developed by Curity', 'color: #626c87; font-size: 18px; font-weight: bold;')
        console.log('%c!!!Attention!!!', 'color: red; font-size: 20px; font-weight: bold; text-align:center;')
        console.log('%cDo not copy paste code that you don\'t understand and trust in this console. \n' +
            'Doing so might make you susceptible to Self-XSS attacks.', 'padding-left:1em; font-size:18px; font-weight: bold;')

        const WrappedAppContainer = (props) => {
            return <>
                {IS_ELECTRON_BUILD && !this.state.userIsLoggedIn &&
                    <Login error={this.state.error} user={this.state.user}
                           checkIdToken={this.checkIdToken} loginHandler={this.loginHandler}/>
                }
                {(!IS_ELECTRON_BUILD || this.state.userIsLoggedIn) &&
                    <React.Fragment>
                        <AppContainer {...props}/>
                    </React.Fragment>
                }
            </>
        };

        const WrappedLoginCallback = (props) => {
            if (!this.state.userIsLoggedIn && !this.state.error) {
                return <LoginCallback {...props} loginHandler={this.loginHandler}/>
            } else {
                return <Navigate to="/"/>
            }
        }

        const WrappedCallbackContainer = (props) => {
            return <CallbackContainer {...props} setCallbackParams={this.setCallbackParams}/>
        };

        const WrappedCreateDemoFlowContainer = (props) => {
            return <CreateDemoFlowContainer {...props} setCallbackParams={this.setCallbackParams}/>
        }

        const supportsHistory = 'pushState' in window.history;

        const TrackedWrappedAppContainer = withTracker(WrappedAppContainer);
        const TrackedWrappedCreateDemoFlowContainer = withTracker(WrappedCreateDemoFlowContainer);
        const TrackedPageContainer = withTracker(PageContainer);
        const TrackedWrappedCallbackContainer = withTracker(WrappedCallbackContainer);

        return (
            <React.Fragment>
                <ErrorBoundary>
                    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
                                   forceRefresh={!supportsHistory}>
                        <React.Fragment>
                            <Routes>
                                {IS_ELECTRON_BUILD &&
                                    <Route exact path="/callback" element={<WrappedLoginCallback/>}/>
                                }
                                <Route exact path="/" element={<TrackedWrappedAppContainer/>}/>
                                <Route exact path="/collection/:collection/"
                                       element={<TrackedWrappedAppContainer/>}/>
                                <Route exact path="/collection/:collection"
                                       element={<TrackedWrappedAppContainer/>}/>
                                <Route path="/callback/:flow" element={<TrackedWrappedCallbackContainer/>}/>
                                <Route path="/about/" element={<TrackedPageContainer/>}/>
                                <Route path="/about" element={<TrackedPageContainer/>}/>
                                {!IS_ELECTRON_BUILD && <>
                                    <Route path="/signicat" element={<TrackedWrappedCreateDemoFlowContainer/>}/>
                                    <Route path="/curity" element={<TrackedWrappedCreateDemoFlowContainer/>}/>
                                </>
                                }
                                {IS_ELECTRON_BUILD &&
                                    <Route path="/changelog" element={<TrackedPageContainer/>}/>
                                }
                                {IS_ELECTRON_BUILD &&
                                    <Route path="/changelog/" element={<TrackedPageContainer/>}/>
                                }
                                <Route exact path="/demo/:demoFlow/"
                                       element={<TrackedWrappedCreateDemoFlowContainer/>}/>
                                <Route exact path="/demo/:demoFlow"
                                       element={<TrackedWrappedCreateDemoFlowContainer/>}/>
                                <Route path="/c/:configHash/" element={<TrackedWrappedAppContainer/>}/>
                                <Route path="/c/:configHash" element={<TrackedWrappedAppContainer/>}/>
                                <Route element={<NotFound/>}/>
                            </Routes>
                            {IS_ELECTRON_BUILD && this.state.showNotification &&
                                <div className={'update-notification'}>
                                    <button className={'button-close'}
                                            onClick={this.closeNotification}>&times;
                                    </button>
                                    <p>{this.state.notificationMessage}
                                    </p>
                                    <button className={'button button-tiny button-primary-outline mr1'}
                                            onClick={this.showWhatsNew}>
                                        See what&apos;s new
                                    </button>

                                    {this.state.showRestartButton &&
                                        <button className={'button button-tiny button-primary'}
                                                id="restart-button" onClick={this.restartApp}>Restart
                                        </button>}
                                </div>}
                            {IS_ELECTRON_BUILD && this.state.showWhatsNew &&
                                <WhatsNew handleClose={this.hideWhatsNew} version={this.state.appVersion}/>}
                        </React.Fragment>
                    </BrowserRouter>
                </ErrorBoundary>
            </React.Fragment>
        );
    }
}

export default Router;
